import { NextPage } from 'next';
import { useMemo } from 'react';
import withSnackbar from './handlers/withSnackbar';
import useUser from '@utils/context/user';
import withEmailVerification from '@utils/hoc/handlers/withEmailVerification';
import withFeatureFlagsCheck from '@utils/hoc/handlers/withFeatureFlagsCheck';
import withPaidCustomerRedirect from '@utils/hoc/handlers/withPaidCustomerRedirect';
import withRestrictedPagesCheck from '@utils/hoc/handlers/withRestrictedPagesCheck';
import withSessionCheck from '@utils/hoc/handlers/withSessionCheck';
import useWithPublicAccess from '@utils/hooks/usePublicAccessCheck';
import useSession from '@utils/hooks/useUserSession';

type Handler<P extends object> = (Component: NextPage<P>) => NextPage<P>;

export interface PropsWithAccess {
    hasReadAccess: boolean;
}

// Helper function to determine if the props object contains read access information
const isPropsWithAccess = (props: object | PropsWithAccess): props is PropsWithAccess => {
    return 'hasReadAccess' in props;
};

// Functions to get the appropriate handlers based on access level
const getPublicAccessHandlers = <P extends object>(): Handler<P>[] => {
    return [withSnackbar];
};

const getDefaultHandlers = <P extends object>(requiredFeatureFlags: string): Handler<P>[] => {
    return [
        withSessionCheck,
        withSnackbar,
        withEmailVerification,
        withRestrictedPagesCheck,
        withPaidCustomerRedirect,
        (Comp) => (requiredFeatureFlags ? withFeatureFlagsCheck(Comp, requiredFeatureFlags) : Comp),
    ];
};

// Central function to get the appropriate handlers based on access level
const getHandlers = <P extends object>(
    hasPublicAccess: boolean,
    requiredFeatureFlags: string = '',
): Handler<P>[] => {
    return hasPublicAccess
        ? getPublicAccessHandlers<P>()
        : getDefaultHandlers<P>(requiredFeatureFlags);
};

// Higher-order component (HOC) that applies multiple restrictions and checks to a component
const withRestrictions = <P extends PropsWithAccess | object>(
    Component: NextPage<P>,
    requiredFeatureFlags: string = '',
): NextPage<P> => {
    const applyHandlers = (handlers: Handler<P>[], Comp: NextPage<P>) =>
        handlers.reduceRight((WrappedComponent, handler) => handler(WrappedComponent), Comp);

    return (props) => {
        // Determine if the user has public access
        const hasReadAccess = isPropsWithAccess(props) ? props?.hasReadAccess : false;
        const [hasPublicAccess, isPublicAccessPrivilegeLoading] =
            useWithPublicAccess(hasReadAccess);
        const { loading: isUserLoading } = useUser();
        const { isLoading: isUserSessionLoading } = useSession();

        const handlersToApply = useMemo(() => {
            return getHandlers<P>(hasPublicAccess, requiredFeatureFlags);
        }, [hasPublicAccess]);

        const EnhancedComponent = useMemo(() => {
            return applyHandlers(handlersToApply, Component);
        }, [handlersToApply]);

        // stop HOC if data is loading
        if (isUserLoading || isUserSessionLoading || isPublicAccessPrivilegeLoading) {
            return null;
        }

        return <EnhancedComponent {...props} />;
    };
};

export default withRestrictions;
