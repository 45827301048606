import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';
import useSession from '@utils/hooks/useUserSession';

const withSessionCheck =
    <P extends object>(Component: NextPage<P>): NextPage<P> =>
    (props) => {
        const router = useRouter();
        const { logout, user } = useAuth();
        const { privileges } = useUser();
        const { isSessionValid } = useSession();
        const redirectUrl = encodeURIComponent(router.asPath);

        useEffect(() => {
            if (!isSessionValid && user) {
                router.push(`/session-logout?redirectUrl=${redirectUrl}`);
                logout(false);
            } else if (!privileges) {
                router.push(`/signin?redirectUrl=${redirectUrl}`);
            }
        }, [isSessionValid, user, privileges, redirectUrl, router, logout]);

        if (!isSessionValid || !privileges) {
            return null;
        }

        return <Component {...props} />;
    };

export default withSessionCheck;
