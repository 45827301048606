import { NextPage } from 'next';
import ExperimentalFeatureDialog from '@components/dialogs/ExperimentalFeatureDialog';
import useUser from '@utils/context/user';

const withFeatureFlagsCheck =
    <P extends object>(Component: NextPage<P>, requiredFeatureFlags: string): NextPage<P> =>
    (props) => {
        const { privileges } = useUser();
        const { featureFlags } = privileges || {};

        if (requiredFeatureFlags && !featureFlags?.includes(requiredFeatureFlags)) {
            return <ExperimentalFeatureDialog />;
        }

        return <Component {...props} />;
    };

export default withFeatureFlagsCheck;
