import { NextPage } from 'next';
import { useRouter } from 'next/router';
import RestrictedPrivilegeFallback from '@components/RestrictedPrivilegeFallback';
import { NON_PAID_USER__UNRESTRICTED_URL } from '@utils/consts/urls';
import useUser from '@utils/context/user';

const withRestrictedPagesCheck =
    <P extends object>(Component: NextPage<P>): NextPage<P> =>
    (props) => {
        const { pathname } = useRouter();
        const { privileges } = useUser();
        const { isAllowedToConsumePaidContent } = privileges || {};

        if (!NON_PAID_USER__UNRESTRICTED_URL.includes(pathname) && !isAllowedToConsumePaidContent) {
            return <RestrictedPrivilegeFallback />;
        }

        return <Component {...props} />;
    };

export default withRestrictedPagesCheck;
