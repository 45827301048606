import { NextPage } from 'next';
import useAlertSnackBar from '@utils/hooks/useAlertSnackBar';

const withSnackbar =
    <P extends object>(Component: NextPage<P>): NextPage<P> =>
    (props) => {
        const [successSnackBar] = useAlertSnackBar({
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            severity: 'success',
        });

        return (
            <>
                <Component {...props} />
                {successSnackBar}
            </>
        );
    };

export default withSnackbar;
