import { NextPage } from 'next';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import ConnexionFlowLayout from '@components/ConnexionFlowLayout';
import VerifyEmail, { EMAIL_VERIFIED_QUERY_KEY } from '@components/VerifyEmail';
import { sendOnboardingEmail } from '@functions';
import { APP_NAME } from '@utils/consts/livv';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';
import { useAlertSnackBar } from '@utils/hooks';

const withEmailVerification =
    <P extends object>(Component: NextPage<P>): NextPage<P> =>
    (props) => {
        const [, setSuccessMessage] = useAlertSnackBar({
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            severity: 'success',
        });
        const { t } = useTranslation('common');
        const router = useRouter();
        const { user } = useAuth();
        const { privileges } = useUser();
        const { isEmailVerified } = privileges || {};

        useEffect(() => {
            if (user && user.emailVerified && router.query[EMAIL_VERIFIED_QUERY_KEY]) {
                setSuccessMessage(
                    t('emailVerification:verificationSuccessful', { appName: APP_NAME }),
                );
                window.history.replaceState(
                    null,
                    '',
                    router.asPath.replace(
                        new RegExp(`(\\?|\\&)${EMAIL_VERIFIED_QUERY_KEY}=true`),
                        '',
                    ),
                );
                sendOnboardingEmail({ uid: user.uid });
            }
            // Display message only once
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user]);

        if (!isEmailVerified) {
            return (
                <ConnexionFlowLayout>
                    <VerifyEmail />
                </ConnexionFlowLayout>
            );
        }

        return <Component {...props} />;
    };

export default withEmailVerification;
