import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { PAID_USER_RESTRICTED_URL } from '@utils/consts/urls';
import useUser from '@utils/context/user';

const withPaidCustomerRedirect =
    <P extends object>(Component: NextPage<P>): NextPage<P> =>
    (props) => {
        const router = useRouter();
        const { pathname } = router;
        const { privileges } = useUser();
        const { isPaidCustomer } = privileges || {};

        if (PAID_USER_RESTRICTED_URL.includes(pathname) && isPaidCustomer) {
            router.push('/');

            return null;
        }

        return <Component {...props} />;
    };

export default withPaidCustomerRedirect;
